/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/** @namespace Seedsman/Util/Script/importScript */
export const importScript = (resourceUrl, scriptId, callback = null) => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.id = scriptId;
    script.async = true;
    script.rel = 'preconnect';
    if (callback) {
        script.onload = callback;
    }
    document.head.appendChild(script);
};

/** @namespace Seedsman/Util/Script/removeScript */
export const removeScript = (scriptId) => {
    const s = document.getElementById(scriptId);
    if (s) {
        document.head.removeChild(s);
    }
};

/** @namespace Seedsman/Util/Script/importLink */
export const importLink = (rel, href, linkId, hrefLang = '', class_name) => {
    const link = document.createElement('link');
    link.rel = rel;
    link.href = href;
    link.id = linkId;
    { hrefLang && link.setAttribute('hrefLang', hrefLang); }
    if (class_name) {
        link.className = class_name;
        document.head.appendChild(link);
    } else {
        document.head.appendChild(link);
    }
};

// Function to dynamically add hreflang link elements
/** @namespace Seedsman/Util/Script/hrefLangLink */
export const hrefLangLink = (path) => {
    const baseUrl = path.origin;
    const basePath = path.pathname.split('/').slice(2).join('/') || '';

    const languages = [
        { hrefLang: 'en-us', pathSuffix: '/us-en/' },
        { hrefLang: 'en-gb', pathSuffix: '/uk-en/' },
        { hrefLang: 'bg-bg', pathSuffix: '/eu-bg/' },
        { hrefLang: 'fr-fr', pathSuffix: '/eu-fr/' },
        { hrefLang: 'de-de', pathSuffix: '/eu-de/' },
        { hrefLang: 'en-za', pathSuffix: '/za-en/' },
        { hrefLang: 'x-default', pathSuffix: '/us-en/' }
    ];

    languages.forEach(({ hrefLang, pathSuffix }) => {
        const link = document.createElement('link');
        link.rel = 'alternate';
        link.href = `${baseUrl}${pathSuffix}${basePath}`;

        if (hrefLang) {
            link.setAttribute('hreflang', hrefLang);
        }

        document.head.appendChild(link);
    });
};

// Remove existing hreflang links before adding new ones
/** @namespace Seedsman/Util/Script/removeHrefLangLink */
export const removeHrefLangLink = () => {
    const links = document.querySelectorAll('link[rel="alternate"]');
    if (links.length > 0) {
        links.forEach((link) => {
            link.remove();
        });
    }
};

/** @namespace Seedsman/Util/Script/publishEvent */
export const publishEvent = (eventName, { type, data }) => {
    const event = new CustomEvent(eventName, {
        detail: {
            type,
            data
        }
    });

    document.dispatchEvent(event);
};
